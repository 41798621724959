const bus = useEventBus<string>("main");

export const useScrollOnChatUpdate = (el: HTMLDivElement | null) => {
  bus.on(listener);

  async function listener(event: string) {
    if (event == "chat:updated") {
      // Wait 10 miliseconds
      await new Promise((resolve) => setTimeout(resolve, 10));
      // Check if scrollable element exists
      if (el) {
        // Scroll to bottom
        el.scrollTo({
          top: el.offsetHeight,
          behavior: "smooth",
        });
      }
    }
  }
};
